<style lang="scss" scoped>
.page-child-contianer {
  overflow: hidden;
  display: flex;
  .progress_left {
    width: 416px;
    padding: 10px;
    border-radius: 5px;
    background-color: #ffffff;
    border: 1px solid #ececec;
    overflow-y: auto;
    overflow-x: hidden;
    float: left;
    color: #000000;
    h3 {
      font-size: 16px;
      font-weight: 200;
      text-align: center;
      line-height: 22px;
      border-bottom: 1px solid #ececec;
      padding-bottom: 5px;
    }
    .form_com_box {
      width: 100%;
      .formitem {
        > div {
          margin-top: 15px;
          border-bottom: 1px solid #ececec;
          padding-bottom: 10px;
          p {
            font-size: 13px;
            color: #666666;
            margin-bottom: 5px;
            i {
              font-size: 25px;
              float: right;
              color: #67c23a;
              margin-top: -7px;
              cursor: pointer;
              opacity: 0.9;
              &:hover {
                opacity: 1;
              }
              &:active {
                margin-top: -6px;
              }
            }
          }
        }
      }
    }
  }
  .progress_center {
    width: 80%;
    float: left;
    margin-left: 20px;
    padding: 10px;
    overflow: hidden;
    overflow-y: auto;
    overflow-x: hidden;
    border-radius: 5px;
    background-color: #ffffff;
    border: 1px solid #ececec;
    text-align: center;
    .form_self_item {
      width: 100%;
      padding: 10px 0 10px 0;
      border-bottom: 1px solid #ececec;
      overflow: hidden;
      .comname {
        width: 150px;
        text-align: right;
        font-size: 15px;
        color: #333333;
        float: left;
      }
      .comcurrent {
        float: left;
        margin-left: 20px;
        text-align: left;
        min-width: 500px;
        .sys {
          display: inline-block;
          border: 1px solid #1989fa;
          color: #1989fa;
          font-size: 13px;
          padding: 5px 5px 2px 5px;
          margin-left: 10px;
        }
        .el-icon-edit,
        .el-icon-delete {
          color: #409eff;
          font-size: 25px;
          margin-left: 10px;
          cursor: pointer;
          margin-top: 3px;
          opacity: 0.9;
          &:hover {
            opacity: 1;
          }
          &:active {
            font-size: 23px;
          }
        }
        .el-icon-delete {
          color: #f56c6c;
        }
      }
    }
  }
  .progress_right {
    width: 250px;
    padding: 10px;
    border-radius: 5px;
    background-color: #ffffff;
    border: 1px solid #ececec;
    float: right;
    margin-left: 20px;
    color: #000000;
  }
  .upload-demo .el-upload-dragger {
    width: 200px !important;
  }
}
</style>

<template>
  <section class="page-child-contianer" v-loading="loading">
    <div class="progress_left">
      <h3>表单组件</h3>
      <div class="form_com_box">
        <div class="formitem" v-for="(item, index) in progressFormTypeList" :key="index">
          <div v-if="item.id == 'input'">
            <p>
              单行输入框
              <i
                @click="pushComToQueue(item)"
                class="el-icon-circle-plus-outline"
                title="添加该组件"
              ></i>
            </p>
            <el-input placeholder="单行输入框组件"></el-input>
          </div>

          <div v-if="item.id == 'number'">
            <p>
              数字计数器
              <i
                @click="pushComToQueue(item)"
                class="el-icon-circle-plus-outline"
                title="添加该组件"
              ></i>
            </p>
            <el-input-number
              style="width: 100%"
              :min="0"
              :max="10000"
              label="数字计数器"
            ></el-input-number>
          </div>

          <div v-if="item.id == 'datetime'">
            <p>
              日期时间选择
              <i
                @click="pushComToQueue(item)"
                class="el-icon-circle-plus-outline"
                title="添加该组件"
              ></i>
            </p>
            <el-date-picker
              style="width: 100%"
              type="datetime"
              placeholder="日期时间选择组件"
            >
            </el-date-picker>
          </div>
          <div v-if="item.id == 'select'">
            <p>
              下拉选择器
              <i
                @click="pushComToQueue(item)"
                class="el-icon-circle-plus-outline"
                title="添加该组件"
              ></i>
            </p>
            <el-select placeholder="下拉选择器" style="width: 100%">
              <el-option
                v-for="item in [{ id: 1, name: '下拉选项1' }]"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </div>
          <div v-if="item.id == 'textarea'">
            <p>
              多行文本域
              <i
                @click="pushComToQueue(item)"
                class="el-icon-circle-plus-outline"
                title="添加该组件"
              ></i>
            </p>
            <el-input type="textarea" :rows="3" placeholder="多行文本域组件"> </el-input>
          </div>

          <div v-if="item.id == 'file'">
            <p>
              文件上传组件
              <i
                @click="pushComToQueue(item)"
                class="el-icon-circle-plus-outline"
                title="添加该组件"
              ></i>
            </p>
            <el-upload
              class="upload-demo"
              action="test"
              drag
              multiple
              style="width: 200px"
            >
              <i class="el-icon-upload"></i>
              <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
              <div class="el-upload__tip" slot="tip">文件上传组件</div>
            </el-upload>
          </div>
        </div>
      </div>
      <el-button
        type="primary"
        style="width: 60%; margin-left: 20%; margin-top: 30px"
        @click="$router.push('/workorderprogress/index/list')"
        >返回</el-button
      >
    </div>
    <div class="progress_center">
      <div class="form_self_item">
        <div class="comname">填报人员</div>
        <div class="comcurrent">
          <el-input disabled style="width: 200px" placeholder="输入填报人员"></el-input
          ><span class="sys">系统字段，必选</span>
        </div>
      </div>
      <div class="form_self_item">
        <div class="comname">填报日期</div>
        <div class="comcurrent">
          <el-date-picker
            disabled
            style="width: 200px"
            type="datetime"
            placeholder="日期时间选择组件"
          >
          </el-date-picker
          ><span class="sys">系统字段，必选</span>
        </div>
      </div>
      <div class="form_self_item">
        <div class="comname">业主位置</div>
        <div class="comcurrent">地图选择组件<span class="sys">系统字段，必选</span></div>
      </div>
      <div class="form_self_item">
        <div class="comname">选择项目</div>
        <div class="comcurrent">
          <el-select disabled placeholder="下拉选择器" style="width: 350px">
            <el-option
              v-for="item in [{ id: 1, name: '下拉选项1' }]"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option> </el-select
          ><span class="sys">系统字段，必选</span>
        </div>
      </div>
      <div class="form_self_item">
        <div class="comname">选择单位</div>
        <div class="comcurrent">
          <el-select placeholder="下拉选择器" disabled style="width: 350px">
            <el-option
              v-for="item in [{ id: 1, name: '下拉选项1' }]"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option> </el-select
          ><span class="sys">系统字段，必选</span>
        </div>
      </div>
      <div class="form_self_item">
        <div class="comname">选择人员</div>
        <div class="comcurrent">
          <el-select placeholder="下拉选择器" disabled style="width: 350px">
            <el-option
              v-for="item in [{ id: 1, name: '下拉选项1' }]"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option> </el-select
          ><span class="sys">系统字段，必选</span>
        </div>
      </div>
      <!-- //其他自定义字段 -->
      <div
        class="form_self_item"
        v-for="(item, index) in progressFormComList"
        v-if="item.field_name != 'id'"
        :key="index"
      >
        <div class="comname">{{ item.name }}</div>
        <div class="comcurrent">
          <div v-if="item.type == 'input'">
            <el-input
              style="width: 350px"
              v-model="item.default_value"
              placeholder="单行输入框组件"
            ></el-input>
            <i class="el-icon-edit" @click="editCom(item)"></i>
            <i @click="deleteCom(item)" class="el-icon-delete"></i>
          </div>

          <div v-if="item.type == 'number'">
            <el-input-number
              style="width: 350px"
              :min="0"
              v-model="item.default_value"
              :max="10000"
              label="数字计数器"
            ></el-input-number>
            <i class="el-icon-edit" @click="editCom(item)"></i>
            <i @click="deleteCom(item)" class="el-icon-delete"></i>
          </div>

          <div v-if="item.type == 'datetime'">
            <el-date-picker
              style="width: 350px"
              type="datetime"
              v-model="item.default_value"
              placeholder="日期时间选择组件"
            >
            </el-date-picker>
            <i class="el-icon-edit" @click="editCom(item)"></i>
            <i @click="deleteCom(item)" class="el-icon-delete"></i>
          </div>
          <div v-if="item.type == 'select'">
            <el-select placeholder="下拉选择器" style="width: 350px">
              <el-option
                v-for="item in item.resource_value_arr"
                :key="item"
                :label="item"
                :value="item"
              >
              </el-option>
            </el-select>
            <i class="el-icon-edit" @click="editCom(item)"></i>
            <i @click="deleteCom(item)" class="el-icon-delete"></i>
          </div>
          <div v-if="item.type == 'textarea'">
            <el-input
              style="width: 350px"
              type="textarea"
              v-model="item.default_value"
              :rows="3"
              placeholder="多行文本域组件"
            >
            </el-input>
            <i class="el-icon-edit" @click="editCom(item)"></i>
            <i @click="deleteCom(item)" class="el-icon-delete"></i>
          </div>

          <div v-if="item.type == 'file'">
            <el-upload
              class="upload-demo"
              action="test"
              drag
              multiple
              style="width: 350px; display: inline-block"
            >
              <i class="el-icon-upload"></i>
              <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
              <div class="el-upload__tip" slot="tip">文件上传组件</div>
            </el-upload>
            <i class="el-icon-edit" @click="editCom(item)"></i>
            <i @click="deleteCom(item)" class="el-icon-delete"></i>
          </div>
        </div>
      </div>
    </div>
    <progressselfformcom
      @saveFormComEventHandler="saveFormComEventHandler"
      ref="progressselfformcom"
    ></progressselfformcom>
  </section>
</template>

<script>
import { getBaseConfig } from "@api/common";
import {
  workOrderProgressDetail,
  workOrderProgressFormSave,
  workOrderProgressFormList,
  workOrderProgressFormDel,
} from "@api/workOrderProgress";
import { getWorkOrderList } from "@api/workOrder";
import progressselfformcom from "@/components/progressselfformcom";
export default {
  name: "progressform",
  inject: ["reload"],
  /**
   * 组件
   */
  components: { progressselfformcom },
  data() {
    return {
      id: null, //修改用户id
      imageUrl: "",
      progressFormTypeList: [],
      isWorkOrderOver: false,
      //组件列表
      progressFormComList: [],
      currentActiveCom: {},
      loading: false,
      formData: {
        sort: 200,
        checkList: [],
      }, //表单存储数据
      rules: {
        name: [{ required: true, message: "请输入工单名称", trigger: "blur" }],
      }, //表单校验规则
    };
  },
  watch: {},
  async created() {
    if (this.$route.params.id) {
      this.id = this.$route.params.id;
      let res = await workOrderProgressDetail(this.id);
      this.workOrderObj = res.data;
    } else this.$message.error("工单无效");
  },
  async mounted() {
    this.getData();
    let res = await getBaseConfig();
    for (let k in res.data.field_type_name) {
      this.progressFormTypeList.push({
        id: k,
        name: res.data.field_type_name[k],
      });
    }
    console.log("progressFormTypeList", this.progressFormTypeList);
  },
  methods: {
    /**
     * 编辑
     */
    editCom: async function (data) {
      data.isEdit = true;
      this.$refs.progressselfformcom.showFormDialogBox(data);
    },
    /**
     * 删除
     */
    deleteCom: async function (data) {
      /** 弹窗再次确认操作 */
      this.$confirm("此操作将永久删除该字段, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        /** 确认触发 */
        .then(async () => {
          /**
           * 请求接口删除数据
           */
          let res = await workOrderProgressFormDel({ id: data.id });
          if (res.code == 200) {
            this.$message({
              type: "success",
              message: res.msg,
            });
          }
          this.getData();
        })
        /** 取消触发 */
        .catch(() => {
          this.$message({
            type: "info",
            message: "操作异常",
          });
        });
    },
    /**
     * 保存组件添加
     */
    saveFormComEventHandler: async function (data) {
      data.work_order_id = this.id;
      let res = await workOrderProgressFormSave(data);
      if (res.code == 200) {
        this.$message({
          type: "success",
          message: res.msg,
        });
        this.getData();
      }
    },
    /**
     * 添加组件
     */
    pushComToQueue: function (item) {
      this.$refs.progressselfformcom.showFormDialogBox(item);
    },
    getData: async function () {
      this.loading = true;
      let res = await workOrderProgressFormList({ work_order_id: this.id });
      this.progressFormComList = res.data;
      this.loading = false;
      console.log(this.progressFormComList);
    },
  },
};
</script>
