import API from "@utils/request";

const COMMON = "/common/";

/**
 * 文件上传
 * @param {file} file
 */
export async function uploadFile(file) {
  try {
    const formData = new FormData();
    formData.append("file", file, file.name);

    return await API.post(COMMON + "/uploadfile", formData, {
      transformRequest: [
        function (data) {
          return data;
        },
      ],
    });
  } catch (error) {
    return error;
  }
}

/**
 * 获取系统配置项
 * @returns
 */
export async function getBaseConfig() {
  try {
    return await API.post("index/systemFileConfigList");
  } catch (error) {
    return error;
  }
}

/**
 * 获取首页数据
 * @returns
 */
export async function $getIndexInfo() {
  try {
    return await API.post("/index/index");
  } catch (error) {
    return error;
  }
}

/** 导出-get */
// export async function 方法名(params) {
//   try {
//     return await API.get("接口名称",{
//       params,
//       responseType: "blob",
//     });
//   } catch (error) {
//     return error;
//   }
// }

/** 导出-post */
// export async function 方法名(params) {
//   try {
//     return await API.post("接口名称", params, {
//       responseType: "blob",
//     });
//   } catch (error) {
//     return error;
//   }
// }