<style lang="scss" scoped>
.el-form-item {
}
.form-line-right {
  float: right;
  margin-top: 10px;
}
</style>

<template>
  <div class="progressform">
    <el-dialog title="表单组件设置" width="500px" :visible.sync="dialogFormVisible">
      <el-form
        ref="formTypeData"
        label-width="110px"
        :rules="rules"
        :model="formTypeData"
      >
        <el-form-item label="组件类型">
          {{ formTypeData.typename }}
        </el-form-item>
        <el-form-item label="显示名称" prop="name">
          <el-input
            v-model="formTypeData.name"
            placeholder="请输入显示名称"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item label="英文标识" prop="field_name">
          <el-input
            :disabled="isEdit"
            onkeyup="value=value.replace(/[^a-zA-Z]/g,'')"
            v-model="formTypeData.field_name"
            placeholder="请输入字段英文标识(英文字母),长度2-20个字符"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item label="提示信息" prop="tips">
          <el-input
            v-model="formTypeData.tips"
            placeholder="请输入提示信息"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="默认值"
          prop="default_value"
          v-if="formTypeData.type != 'file' && formTypeData.type == 'datetime'"
        >
          <el-date-picker
            style="width: 350px"
            type="datetime"
            v-model="formTypeData.default_value"
            placeholder="日期时间选择组件"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item
          label="默认值"
          prop="default_value"
          v-if="formTypeData.type != 'file' && formTypeData.type != 'datetime'"
        >
          <el-input
            v-model="formTypeData.default_value"
            placeholder="无"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="下拉选项值"
          prop="resource_value"
          v-if="formTypeData.type == 'select'"
        >
          <el-input
            style="width: 350px"
            type="textarea"
            v-model="formTypeData.resource_value"
            :rows="3"
            placeholder="下拉选择数据源:多个以”,”(英文逗号)分割,如：足球,篮球,乒乓球"
          >
          </el-input>
        </el-form-item>
        <el-form-item
          label="字段长度"
          prop="tips"
          v-if="
            formTypeData.type != 'datetime' &&
            formTypeData.type != 'file' &&
            formTypeData.type != 'select'
          "
        >
          <el-input-number
            style="width: 48%"
            :min="0"
            :max="10000"
            v-model="formTypeData.length_min"
            label="数字计数器"
          ></el-input-number>
          -
          <el-input-number
            style="width: 48%"
            :min="0"
            v-model="formTypeData.length_max"
            :max="10000"
            label="数字计数器"
          ></el-input-number>
        </el-form-item>
        <el-form-item label="排序" prop="sort">
          <el-input-number
            style="width: 100%"
            v-model="formTypeData.sort"
            label="数字计数器"
          ></el-input-number>
        </el-form-item>
        <el-form-item label="状态" prop="status">
          <el-switch
            v-model="formTypeData.status"
            active-color="#13ce66"
            active-text="启用"
            inactive-text="禁用"
            inactive-color="#ff4949"
          >
          </el-switch>
        </el-form-item>
        <el-form-item label="是否必填" prop="is_need">
          <el-switch
            v-model="formTypeData.is_need"
            active-color="#13ce66"
            active-text="必填"
            inactive-text="不必填"
            inactive-color="#ff4949"
          >
          </el-switch>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="saveData" :loading="btnSubmitLoading"
          >确 定</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { getBaseConfig } from "@api/common";
import { getBranchList } from "@api/branch";
import {
  workOrderProgressStepAdd,
  workOrderProgressStepList,
  workOrderProgressStepDetail,
  workOrderProgressStepEdit,
} from "@api/workOrderProgress";
import { getFrontRoleList } from "@api/frontRole";

export default {
  name: "progressformcom",
  props: [],
  /**
   * 组件
   */
  components: {},
  data() {
    return {
      dialogFormVisible: false,
      //是否是更新操作
      isEdit: false,
      btnSubmitLoading: false,
      rules: {
        name: [{ required: true, message: "请输入字段显示名称", trigger: "blur" }],
        field_name: [
          {
            required: true,
            message: "请输入字段英文标识(英文字母），且长度在2-20个字符之间",
            trigger: "blur",
            validator: function (rule, value, callback) {
              if (!value || value.length < 2 || value.length > 20) {
                return callback(new Error("英文标识不能为空，且长度在2-20个字符之间"));
              } else callback();
            },
          },
        ],
        resource_value: [
          { required: true, message: "请输入下拉选择的选项", trigger: "blur" },
        ],
      },
      formTypeData: {},
    };
  },
  model: {
    prop: "value",
    event: "change",
  },
  watch: {},
  async mounted() {},
  beforeDestroy() {},
  methods: {
    saveData: function () {
      this.$refs["formTypeData"].validate(async (valid) => {
        if (valid) {
          this.formTypeData.is_need == true ? 1 : 2;
          this.formTypeData.status == true ? 1 : 2;
          this.$emit("saveFormComEventHandler", this.formTypeData);
          this.dialogFormVisible = false;
        }
      });
    },
    showFormDialogBox: function (data) {
      this.formTypeData = data;
      this.isEdit = data.isEdit;
      if (data.isEdit) {
        this.formTypeData = {
          id: data.id,
          typename: data.name,
          name: data.name,
          type: data.type,
          tips: data.tips,
          field_name: data.field_name,
          length_min: data.length_min,
          length_max: data.length_max,
          resource_value: data.resource_value,
          sort: data.sort,
          status: data.status == 1,
          is_need: data.is_need == 1,
          default_value: data.default_value,
        };
      } else
        this.formTypeData = {
          typename: data.name,
          name: "",
          type: data.id,
          tips: "",
          field_name: "",
          length_min: 0,
          length_max: 50,
          sort: 200,
          status: true,
          is_need: true,
          default_value: "",
        };
      console.log("formTypeData", data);
      this.dialogFormVisible = true;
    },
  },
};
</script>
